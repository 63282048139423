import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Container, Row, Col, Card, Modal, Form, Spinner, Breadcrumb, ProgressBar, Dropdown, Button } from 'react-bootstrap';
import { FaFolder, FaStar, FaPlus } from 'react-icons/fa';
import { DndProvider, useDrag, useDrop } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { useDropzone } from 'react-dropzone';
import TagsInput from 'react-tagsinput';
import 'react-tagsinput/react-tagsinput.css';

const API_URL = process.env.REACT_APP_API_URL;

const DraggableFile = ({ file, index, moveFile, findFile, handleDragEnd, handleEditClick, handleDelete }) => {
  const originalIndex = findFile(file.id).index;
  const [{ isDragging }, drag] = useDrag({
    type: 'file',
    item: { id: file.id, originalIndex },
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
    end: (item, monitor) => {
      const { id: droppedId, originalIndex } = item;
      const didDrop = monitor.didDrop();
      if (!didDrop) moveFile(droppedId, originalIndex);
      else handleDragEnd();
    },
  });
  const [, drop] = useDrop({
    accept: 'file',
    hover({ id: draggedId }) {
      if (draggedId !== file.id) {
        const { index: overIndex } = findFile(file.id);
        moveFile(draggedId, overIndex);
      }
    },
  });
  return (
    <Col md={3} ref={(node) => drag(drop(node))} className="mb-4" style={{ opacity: isDragging ? 0.5 : 1 }}>
      <Card className="file-card p-3">
        <Row className="align-items-center">
          <Col md={12}>
            <h5>{file.title} {file.isImportant && <FaStar style={{ color: 'gold' }} />}</h5>
          </Col>
          <Col md={12} className="text-right">
            <Dropdown>
              <Dropdown.Toggle variant="light" id="dropdown-basic">&#x22EE;</Dropdown.Toggle>
              <Dropdown.Menu>
                <Dropdown.Item onClick={() => handleEditClick(file)}>Изменить</Dropdown.Item>
                <Dropdown.Item onClick={() => handleDelete(file.id)}>Удалить</Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
          </Col>
        </Row>
      </Card>
    </Col>
  );
};

const DroppableFolder = ({ folder, moveFileToFolder, handleFolderClick }) => {
  const [, drop] = useDrop({
    accept: 'file',
    drop: (item) => moveFileToFolder(item.id, folder.id),
  });
  return (
    <Col md={3} ref={drop} className="mb-4" onClick={() => handleFolderClick(folder)}>
      <Card className="folder-card">
        <Card.Body className="text-center">
          <FaFolder size={50} />
          <h5>{folder.name}</h5>
        </Card.Body>
      </Card>
    </Col>
  );
};

function FileManager({ authToken }) {
  const [categories, setCategories] = useState([]);
  const [currentCategory, setCurrentCategory] = useState(null);
  const [files, setFiles] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [showCreateFolderModal, setShowCreateFolderModal] = useState(false);
  const [newFolderName, setNewFolderName] = useState('');
  const [breadcrumbs, setBreadcrumbs] = useState([]);
  const [uploadProgress, setUploadProgress] = useState({});
  const [showUploadModal, setShowUploadModal] = useState(false);
  const [showEditModal, setShowEditModal] = useState(false);
  const [editFileId, setEditFileId] = useState(null);
  const [editTitle, setEditTitle] = useState('');
  const [editTags, setEditTags] = useState([]);
  const [editImportant, setEditImportant] = useState(false);
  const [editMarkers, setEditMarkers] = useState('');

  const handleFileUpload = (acceptedFiles) => {
    setShowUploadModal(true);
    let totalProgress = {};
    acceptedFiles.forEach((file) => { totalProgress[file.name] = 0; });
    setUploadProgress(totalProgress);
    const uploadFile = (file) => {
      const formData = new FormData();
      formData.append('file', file);
      if (currentCategory) formData.append('category', currentCategory.id);
      return axios.post(`${API_URL}/admin/files`, formData, {
        headers: { Authorization: `Bearer ${authToken}`, 'Content-Type': 'multipart/form-data' },
        onUploadProgress: (progressEvent) => {
          const progress = Math.round((progressEvent.loaded * 100) / progressEvent.total);
          setUploadProgress((prev) => ({ ...prev, [file.name]: progress }));
        },
      });
    };
    acceptedFiles.reduce((promiseChain, file) => promiseChain.then(() => uploadFile(file)), Promise.resolve()).then(() => {
      setShowUploadModal(false);
      fetchFiles(currentCategory ? currentCategory.id : null);
    }).catch((error) => {
      console.error('Error uploading files:', error);
      setShowUploadModal(false);
    });
  };

  const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop: handleFileUpload, noClick: true });

  const moveFile = (draggedId, overIndex) => {
    const draggedFile = files.find((file) => file.id === draggedId);
    const updatedFiles = files.filter((file) => file.id !== draggedId);
    updatedFiles.splice(overIndex, 0, draggedFile);
    setFiles(updatedFiles);
  };

  const handleDragEnd = () => {
    const fileOrders = files.map((file, index) => ({ id: file.id, sortOrder: index }));
    axios.post(`${API_URL}/admin/files/order`, { fileOrders }, { headers: { Authorization: `Bearer ${authToken}` } })
      .then(() => { console.log('Order updated successfully'); })
      .catch((error) => { console.error('Error updating file order:', error); });
  };

  const handleEditClick = (file) => {
    setEditFileId(file.id);
    setEditTitle(file.title);
    setEditTags(file.tags ? file.tags.split(',') : []);
    setEditImportant(file.isImportant);
    setEditMarkers(file.markers ? file.markers.map(marker => `${marker.time} - ${marker.comment}`).join(', ') : '');
    setShowEditModal(true);
  };

  const handleEditSave = () => {
    const updatedFile = {
      title: editTitle,
      tags: editTags.length > 0 ? editTags.join(',') : '',
      isImportant: editImportant,
      markers: editMarkers
    };

    axios.put(`${API_URL}/admin/files/${editFileId}`, updatedFile, {
      headers: {
        Authorization: `Bearer ${authToken}`,
        'Content-Type': 'application/json',
      },
    }).then(() => {
      setShowEditModal(false);
      fetchFiles(currentCategory ? currentCategory.id : null);
    }).catch((error) => {
      console.error('Error updating file:', error);
    });
  };

  const handleDelete = (fileId) => {
    axios.delete(`${API_URL}/admin/files/${fileId}`, { headers: { Authorization: `Bearer ${authToken}` } })
      .then(() => { fetchFiles(currentCategory ? currentCategory.id : null); })
      .catch((error) => { console.error('Error deleting file:', error); });
  };

  const findFile = (id) => {
    const file = files.find((f) => f.id === id);
    return { file, index: files.indexOf(file) };
  };

  const fetchFiles = (categoryId = null) => {
    setIsLoading(true);
    const params = categoryId ? { category: categoryId } : {};
    axios.get(`${API_URL}/admin/files`, { headers: { Authorization: `Bearer ${authToken}` }, params })
      .then((response) => { setFiles(response.data); setIsLoading(false); })
      .catch((error) => { console.error('Error fetching files:', error); setIsLoading(false); });
  };

  const fetchCategoriesAndRootFiles = () => {
    setIsLoading(true);
    const categoriesRequest = axios.get(`${API_URL}/admin/categories`, { headers: { Authorization: `Bearer ${authToken}` } });
    const filesRequest = axios.get(`${API_URL}/admin/files`, { headers: { Authorization: `Bearer ${authToken}` }, params: { category: null } });
    Promise.all([categoriesRequest, filesRequest]).then(([categoriesResponse, filesResponse]) => {
      setCategories(categoriesResponse.data);
      setFiles(filesResponse.data);
      setBreadcrumbs([]);
      setCurrentCategory(null);
      setIsLoading(false);
    }).catch((error) => { console.error('Error fetching categories and files:', error); setIsLoading(false); });
  };

  useEffect(() => { fetchCategoriesAndRootFiles(); }, []);

  const handleCreateFolder = (e) => {
    e.preventDefault();
    axios.post(`${API_URL}/admin/categories`, { name: newFolderName }, { headers: { Authorization: `Bearer ${authToken}` } })
      .then(() => { setShowCreateFolderModal(false); setNewFolderName(''); fetchCategoriesAndRootFiles(); })
      .catch((error) => { console.error('Error creating folder:', error); });
  };

  const handleBreadcrumbClick = (index) => {
    if (index === 0) fetchCategoriesAndRootFiles();
    else {
      const selectedCrumb = breadcrumbs[index];
      setBreadcrumbs(breadcrumbs.slice(0, index + 1));
      setCurrentCategory(selectedCrumb);
      fetchFiles(selectedCrumb.id);
    }
  };

  const moveFileToFolder = (fileId, folderId) => {
    const updatedFiles = files.filter((file) => file.id !== fileId);
    setFiles(updatedFiles);
    axios.post(`${API_URL}/admin/files/move`, { fileId, folderId }, { headers: { Authorization: `Bearer ${authToken}` } })
      .then(() => { console.log('File moved to folder successfully'); })
      .catch((error) => { console.error('Error moving file to folder:', error); });
  };

  const handleFolderClick = (category) => {
    if (currentCategory?.id === category.id) return;
    if (!breadcrumbs.some(crumb => crumb.id === category.id)) setBreadcrumbs(prev => [...prev, category]);
    setCurrentCategory(category);
    fetchFiles(category.id);
  };

  return (
    <DndProvider backend={HTML5Backend}>
      <Container className="mt-5">
        <h1 className="text-center mb-4">Файловый менеджер</h1>
        <Breadcrumb>
          <Breadcrumb.Item onClick={() => handleBreadcrumbClick(0)}>Корневая папка</Breadcrumb.Item>
          {breadcrumbs.map((crumb, index) => (
            <Breadcrumb.Item key={crumb.id} onClick={() => handleBreadcrumbClick(index)}>
              {crumb.name}
            </Breadcrumb.Item>
          ))}
        </Breadcrumb>
        <div {...getRootProps()} className={`dropzone-area ${isDragActive ? 'drag-active' : ''}`}>
          <input {...getInputProps()} />
          {isDragActive ? (
            <div className="drag-indicator">Отпустите файлы, чтобы загрузить</div>
          ) : (
            <>
              {isLoading ? <Spinner animation="border" /> : (
                <>
                  <Row>
                    {!currentCategory &&
                      categories.map((category) => (
                        <DroppableFolder
                          key={category.id}
                          folder={category}
                          moveFileToFolder={moveFileToFolder}
                          handleFolderClick={handleFolderClick}
                        />
                      ))}
                    {!currentCategory && (
                      <Col md={3} className="mb-4">
                        <Card className="folder-card text-center d-flex justify-content-center align-items-center" onClick={() => setShowCreateFolderModal(true)} style={{ border: '2px dashed #ccc', cursor: 'pointer' }}>
                          <FaPlus size={50} />
                          <Card.Body>
                            <h5>Создать папку</h5>
                          </Card.Body>
                        </Card>
                      </Col>
                    )}
                  </Row>
                  <Row>
                    {files.map((file, index) => (
                      <DraggableFile
                        key={file.id}
                        file={file}
                        index={index}
                        moveFile={moveFile}
                        findFile={findFile}
                        handleDragEnd={handleDragEnd}
                        handleEditClick={handleEditClick}
                        handleDelete={handleDelete}
                      />
                    ))}
                  </Row>
                </>
              )}
            </>
          )}
        </div>
        <Modal show={showUploadModal} onHide={() => setShowUploadModal(false)} backdrop="static" keyboard={false}>
          <Modal.Header><Modal.Title>Загрузка файлов</Modal.Title></Modal.Header>
          <Modal.Body>
            {Object.keys(uploadProgress).map((fileName) => (
              <div key={fileName} className="mb-3">
                <h5>{fileName}</h5>
                <ProgressBar now={uploadProgress[fileName]} label={`${uploadProgress[fileName]}%`} />
              </div>
            ))}
          </Modal.Body>
        </Modal>
        <Modal show={showCreateFolderModal} onHide={() => setShowCreateFolderModal(false)}>
          <Modal.Header closeButton><Modal.Title>Создать папку</Modal.Title></Modal.Header>
          <Form onSubmit={handleCreateFolder}>
            <Modal.Body>
              <Form.Group controlId="newFolderName" className="mb-3">
                <Form.Label>Название папки</Form.Label>
                <Form.Control type="text" value={newFolderName} onChange={(e) => setNewFolderName(e.target.value)} />
              </Form.Group>
            </Modal.Body>
            <Modal.Footer>
              <Button variant="secondary" onClick={() => setShowCreateFolderModal(false)}>Отмена</Button>
              <Button variant="primary" type="submit">Создать</Button>
            </Modal.Footer>
          </Form>
        </Modal>
        <Modal show={showEditModal} onHide={() => setShowEditModal(false)}>
          <Modal.Header closeButton>
            <Modal.Title>Изменить файл</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Form>
              <Form.Group controlId="editTitle" className="mb-3">
                <Form.Label>Название</Form.Label>
                <Form.Control
                  type="text"
                  value={editTitle}
                  onChange={(e) => setEditTitle(e.target.value)}
                />
              </Form.Group>
              <Form.Group controlId="editTags" className="mb-3">
                <Form.Label>Теги</Form.Label>
                <TagsInput value={editTags} onChange={setEditTags} />
              </Form.Group>
              <Form.Group controlId="editMarkers" className="mb-3">
                <Form.Label>Метки времени</Form.Label>
                <Form.Control
                  as="textarea"
                  value={editMarkers}
                  onChange={(e) => setEditMarkers(e.target.value)}
                />
              </Form.Group>
              <Form.Group controlId="editImportant" className="mb-3">
                <Form.Check
                  type="checkbox"
                  label="Избранное"
                  checked={editImportant}
                  onChange={(e) => setEditImportant(e.target.checked)}
                />
              </Form.Group>
            </Form>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={() => setShowEditModal(false)}>Отмена</Button>
            <Button variant="primary" onClick={handleEditSave}>Сохранить</Button>
          </Modal.Footer>
        </Modal>
      </Container>
    </DndProvider>
  );
}

export default FileManager;
