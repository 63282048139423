import React, { useState, useEffect } from 'react';
import axios from 'axios';
import FileList from './FileList';
import { Container, Row, Col, ToastContainer, Toast } from 'react-bootstrap';

const API_URL = process.env.REACT_APP_API_URL;

function AdminPanel({ authToken }) {
    const [files, setFiles] = useState([]);
    const [error, setError] = useState(null);
    const [toastMessage, setToastMessage] = useState(null);

    const fetchFiles = () => {
        axios.get(`${API_URL}/admin/files`, {
            headers: {
                'Authorization': `Bearer ${authToken}` 
            }
        })
        .then(response => setFiles(response.data))
        .catch(error => setError(error.message));
    };

    useEffect(() => {
        fetchFiles(); 
    }, [authToken]);

    const handleFileUploadSuccess = () => {
        fetchFiles(); 
        showToast("Успех, ребят!");
    };

    const handleDelete = () => {
        fetchFiles();
        showToast("Удалили, ребят!");
    };

    const handleEdit = () => {
        fetchFiles();
        showToast("Успех, ребят!");
    };

    const showToast = (message) => {
        setToastMessage(message);
        setTimeout(() => setToastMessage(null), 3000);
    };

    return (
        <Container className="mt-5">
            <h1 className="text-center mb-4">Админ_очка</h1>
            {error && <div className="alert alert-danger">{error}</div>}
            <Row>
                <Col md={12} className="mt-4">
                    <FileList files={files} authToken={authToken} onDelete={handleDelete} onEdit={handleEdit} />
                </Col>
            </Row>
            <ToastContainer position="top-end" className="p-3">
                {toastMessage && (
                    <Toast>
                        <Toast.Header>
                            <strong className="me-auto">ГУ!</strong>
                        </Toast.Header>
                        <Toast.Body>{toastMessage}</Toast.Body>
                    </Toast>
                )}
            </ToastContainer>
        </Container>
    );
}

export default AdminPanel;
