import React, { useState, useEffect, useRef } from 'react';
import { Container, Row, Col, Spinner, Badge, Card, Button, ToggleButtonGroup, ToggleButton, Collapse } from 'react-bootstrap';
import { FaPlay, FaPause, FaStar, FaRedo, FaVolumeUp } from 'react-icons/fa';
import WaveSurfer from 'wavesurfer.js';
import SearchBar from './SearchBar';
import axios from 'axios';

const API_URL = process.env.REACT_APP_API_URL;

function UserFront() {
    const [files, setFiles] = useState([]);
    const [isPlaying, setIsPlaying] = useState({});
    const [playingFileId, setPlayingFileId] = useState(null);
    const [currentTime, setCurrentTime] = useState({});
    const [fileDurations, setFileDurations] = useState({});
    const [isLoading, setIsLoading] = useState(true);
    const [tags, setTags] = useState([]);
    const [selectedTags, setSelectedTags] = useState([]);
    const [showTags, setShowTags] = useState(false);
    const [searchKeyword, setSearchKeyword] = useState('');
    const [volumes, setVolumes] = useState({});
    const [showAllFiles, setShowAllFiles] = useState(false);
    const [showMarkers, setShowMarkers] = useState({});

    const waveSurferRefs = useRef({});
    const waveSurferContainers = useRef({});
    const volumeSliderRefs = useRef({});

    useEffect(() => {
        fetchFiles();
        fetchUniqueTags();
    }, [showAllFiles]);

    const fetchFiles = () => {
        setIsLoading(true);
        const params = {
            important: !showAllFiles
        };
        if (searchKeyword) {
            params.keyword = searchKeyword;
        }
        if (selectedTags.length > 0) {
            params.tags = selectedTags.join(',');
        }

        axios.get(`${API_URL}/files`, { params })
            .then(response => {
                setFiles(response.data);
                setIsLoading(false);
            })
            .catch(error => {
                console.error("Error fetching files:", error);
                setIsLoading(false);
            });
    };

    const fetchUniqueTags = () => {
        axios.get(`${API_URL}/user/tags`)
            .then(response => setTags(response.data))
            .catch(error => console.error("Error fetching tags:", error));
    };

    const createWaveSurfer = (file) => {
        const container = waveSurferContainers.current[file.id];
        if (!container) return;

        if (waveSurferRefs.current[file.id]) {
            waveSurferRefs.current[file.id].destroy();
        }

        const waveSurfer = WaveSurfer.create({
            container,
            waveColor: '#e9ecef',
            progressColor: '#007bff',
            cursorColor: '#007bff',
            height: 40,
            barWidth: 3,
            normalize: true,
            backend: 'MediaElement',
            interact: true,
        });

        waveSurfer.load(file.path);

        waveSurfer.on('ready', () => {
            setFileDurations(prev => ({ ...prev, [file.id]: waveSurfer.getDuration() }));

            // Добавление обработчиков для перетаскивания курсора
            container.addEventListener('mousedown', (e) => {
                e.preventDefault();
                const onMouseMove = (event) => {
                    const rect = container.getBoundingClientRect();
                    const x = event.clientX - rect.left;
                    const width = rect.width;
                    const progress = x / width;
                    waveSurfer.seekTo(progress);

                    // Обновляем текущее время в реальном времени
                    const newTime = progress * waveSurfer.getDuration();
                    setCurrentTime(prev => ({ ...prev, [file.id]: newTime }));
                };

                const onMouseUp = () => {
                    document.removeEventListener('mousemove', onMouseMove);
                    document.removeEventListener('mouseup', onMouseUp);
                };

                document.addEventListener('mousemove', onMouseMove);
                document.addEventListener('mouseup', onMouseUp);
            });
        });

        waveSurfer.on('audioprocess', () => {
            setCurrentTime(prev => ({ ...prev, [file.id]: waveSurfer.getCurrentTime() }));
        });

        waveSurfer.on('seek', (progress) => {
            const duration = waveSurfer.getDuration();
            const newTime = progress * duration;
            setCurrentTime(prev => ({ ...prev, [file.id]: newTime }));
        });

        waveSurfer.on('interaction', () => {
            const currentProgress = waveSurfer.getCurrentTime() / waveSurfer.getDuration();
            setCurrentTime(prev => ({ ...prev, [file.id]: currentProgress * waveSurfer.getDuration() }));
        });

        waveSurfer.on('finish', () => {
            setIsPlaying(prev => ({ ...prev, [file.id]: false }));
            setPlayingFileId(null);
            waveSurfer.seekTo(0);
        });

        waveSurferRefs.current[file.id] = waveSurfer;
    };

    useEffect(() => {
        files.forEach(file => {
            createWaveSurfer(file);
        });
    }, [files]);

    const handlePlayPause = (file) => {
        const waveSurfer = waveSurferRefs.current[file.id];
        if (!waveSurfer) return;

        if (playingFileId && playingFileId !== file.id) {
            const playingWaveSurfer = waveSurferRefs.current[playingFileId];
            if (playingWaveSurfer) {
                playingWaveSurfer.pause();
                setIsPlaying(prev => ({ ...prev, [playingFileId]: false }));
            }
        }

        if (isPlaying[file.id]) {
            waveSurfer.pause();
            setIsPlaying(prev => ({ ...prev, [file.id]: false }));
        } else {
            waveSurfer.play();
            setIsPlaying(prev => ({ ...prev, [file.id]: true }));
            setPlayingFileId(file.id);
        }
    };

    const handleReset = (file) => {
        const waveSurfer = waveSurferRefs.current[file.id];
        if (waveSurfer) {
            waveSurfer.seekTo(0);
            setCurrentTime(prev => ({ ...prev, [file.id]: 0 }));
        }
    };

    const handleVolumeChange = (file, volume) => {
        const waveSurfer = waveSurferRefs.current[file.id];
        if (waveSurfer) {
            waveSurfer.setVolume(volume);
            setVolumes(prev => ({ ...prev, [file.id]: volume }));
        }
    };

    const handleVolumeReset = (file) => {
        const waveSurfer = waveSurferRefs.current[file.id];
        if (waveSurfer) {
            waveSurfer.setVolume(1);
            setVolumes(prev => ({ ...prev, [file.id]: 1 }));
        }
    };

    const handleVolumeMouseDown = (file, e) => {
        e.preventDefault();
        const slider = volumeSliderRefs.current[file.id];
        const rect = slider.getBoundingClientRect();

        const updateVolume = (event) => {
            const y = Math.min(Math.max(event.clientY, rect.top), rect.bottom) - rect.top;
            const height = rect.height;
            let volume = 1 - y / height;
            volume = Math.max(0, Math.min(1, volume));
            handleVolumeChange(file, volume);
        };

        const stopTracking = () => {
            document.removeEventListener('mousemove', updateVolume);
            document.removeEventListener('mouseup', stopTracking);
        };

        document.addEventListener('mousemove', updateVolume);
        document.addEventListener('mouseup', stopTracking);
    };

    const handleTagClick = (tag) => {
        if (!selectedTags.includes(tag)) {
            setSelectedTags([...selectedTags, tag]);
        }
    };

    const removeTag = (tag) => {
        setSelectedTags(selectedTags.filter(t => t !== tag));
    };

    useEffect(() => {
        fetchFiles();
    }, [selectedTags, searchKeyword]);

    const formatTime = (time) => {
        const minutes = Math.floor(time / 60);
        const seconds = Math.floor(time % 60);
        return `${minutes}:${seconds < 10 ? '0' : ''}${seconds}`;
    };

    const parseTime = (timeString) => {
        const [minutes, seconds] = timeString.split(':').map(Number);
        return minutes * 60 + seconds;
    };

    const clearTags = () => {
        setSelectedTags([]);
        fetchFiles();
    };

    const handleMarkerClick = (fileId, timeString) => {
        const waveSurfer = waveSurferRefs.current[fileId];
        const timeInSeconds = parseTime(timeString);
        if (waveSurfer && !isNaN(timeInSeconds)) {
            waveSurfer.seekTo(timeInSeconds / waveSurfer.getDuration());
            if (isPlaying[fileId]) {
                waveSurfer.play(); // Воспроизведение только если файл уже играет
            }
        }
    };

    const toggleMarkers = (fileId) => {
        setShowMarkers(prev => ({ ...prev, [fileId]: !prev[fileId] }));
    };

    return (
        <Container className="mt-5">
            <h1 className="text-center mb-4">КонтЕнт</h1>
            <Row className="mb-3">
                <Col md={9}>
                    <SearchBar onSearch={(keyword) => setSearchKeyword(keyword)} />
                </Col>
                <Col md={3} className="text-end">
                    <ToggleButtonGroup type="radio" name="fileType" defaultValue={showAllFiles} onChange={() => setShowAllFiles(!showAllFiles)}>
                        <ToggleButton id="toggle-important" value={false} variant="outline-primary">
                            Избранное
                        </ToggleButton>
                        <ToggleButton id="toggle-all" value={true} variant="outline-primary">
                            Все файлы
                        </ToggleButton>
                    </ToggleButtonGroup>
                </Col>
            </Row>

            <div className="mb-3">
                <Button onClick={() => setShowTags(!showTags)} aria-controls="tag-collapse" aria-expanded={showTags}>
                    {showTags ? "Скрыть теги" : "Показать теги"}
                </Button>
                <Collapse in={showTags}>
                    <div id="tag-collapse">
                        {tags.map(tag => (
                            <Badge
                                key={tag}
                                pill
                                bg="secondary"
                                className="me-1 mt-2"
                                style={{ cursor: 'pointer' }}
                                onClick={() => handleTagClick(tag)}
                            >
                                {tag}
                            </Badge>
                        ))}
                    </div>
                </Collapse>
            </div>

            {selectedTags.length > 0 && (
                <div className="mb-3">
                    <h6>Выбрано:</h6>
                    {selectedTags.map(tag => (
                        <Badge
                            key={tag}
                            pill
                            bg="primary"
                            className="me-1 mt-2"
                            onClick={() => removeTag(tag)}
                            style={{ cursor: 'pointer' }}
                        >
                            {tag} &times;
                        </Badge>
                    ))}
                    <Button variant="secondary" onClick={clearTags} className="ms-2">Сбросить</Button>
                </div>
            )}

            <Row>
                {isLoading ? (
                    <div className="text-center my-4">
                        <Spinner animation="border" />
                    </div>
                ) : (
                    files.length > 0 ? (
                        files.map((file) => (
                            <Col md={4} key={file.id} className="mb-4">
                                <Card className="p-3">
                                    <Row className="align-items-center">
                                        <Col md={12} className="align-items-center">
                                            <h5>
                                                {file.title} {file.isImportant && <FaStar style={{ color: 'gold' }} />}
                                            </h5>
                                            <p>
                                                {file.tags ? file.tags.split(',').map(tag => (
                                                    <Badge
                                                        key={tag}
                                                        pill
                                                        bg="secondary"
                                                        className="me-1"
                                                        style={{ cursor: 'pointer' }}
                                                        onClick={() => handleTagClick(tag)}
                                                    >
                                                        {tag}
                                                    </Badge>
                                                )) : 'No tags'}
                                            </p>
                                        </Col>
                                    </Row>
                                    <Row className="align-items-center">
                                        <Col md={10} className="d-flex align-items-center">
                                            <div
                                                ref={el => { waveSurferContainers.current[file.id] = el; }}
                                                style={{ width: '100%', height: '60px', position: 'relative' }}
                                            ></div>
                                        </Col>
                                        <Col md={2} className="d-flex flex-column align-items-center">
                                            <div
                                                ref={el => { volumeSliderRefs.current[file.id] = el; }}
                                                onMouseDown={(e) => handleVolumeMouseDown(file, e)}
                                                style={{
                                                    width: '10px',
                                                    height: '40px',
                                                    backgroundColor: '#e9ecef',
                                                    position: 'relative',
                                                    cursor: 'pointer'
                                                }}
                                            >
                                                <div
                                                    style={{
                                                        position: 'absolute',
                                                        bottom: `${volumes[file.id] * 100}%`,
                                                        left: '0',
                                                        right: '0',
                                                        height: '10px',
                                                        backgroundColor: '#007bff',
                                                    }}
                                                ></div>
                                            </div>
                                            <Button
                                                variant="link"
                                                onClick={() => handleVolumeReset(file)}
                                                style={{ fontSize: '1.2rem', marginTop: '5px' }}
                                            >
                                                <FaVolumeUp />
                                            </Button>
                                        </Col>
                                    </Row>
                                    <Row className="align-items-center mt-3 mb-2">
                                        <Col md={4} className="text-start">
                                            <Button
                                                variant="primary"
                                                onClick={() => handlePlayPause(file)}
                                                className="me-2"
                                            >
                                                {isPlaying[file.id] ? <FaPause /> : <FaPlay />}
                                            </Button>
                                            <Button
                                                variant="secondary"
                                                onClick={() => handleReset(file)}
                                                className="me-2"
                                            >
                                                <FaRedo />
                                            </Button>
                                        </Col>
                                        <Col md={1}></Col>
                                        <Col md={6} className="text-left" style={{ textAlign: 'left' }}>
                                            <span>
                                                {formatTime(currentTime[file.id] || 0)} / {formatTime(fileDurations[file.id] || 0)}
                                            </span>
                                        </Col>
                                    </Row>
                                    {file.markers && file.markers.length > 0 && (
                                        <>
                                            <Button
                                                onClick={() => toggleMarkers(file.id)}
                                                aria-controls={`markers-${file.id}`}
                                                aria-expanded={showMarkers[file.id]}
                                                variant="link"
                                            >
                                                Показать метки
                                            </Button>
                                            <Collapse in={showMarkers[file.id]}>
                                                <div id={`markers-${file.id}`}>
                                                    {file.markers.map((marker, index) => (
                                                        <Button
                                                            key={index}
                                                            onClick={() => handleMarkerClick(file.id, marker.time)}
                                                            variant="outline-primary"
                                                            size="sm"
                                                        >
                                                            {marker.comment} ({marker.time})
                                                        </Button>
                                                    ))}
                                                </div>
                                            </Collapse>
                                        </>
                                    )}
                                </Card>
                            </Col>
                        ))
                    ) : (
                        <div className="text-center my-4">
                            <p>Пусто</p>
                        </div>
                    )
                )}
            </Row>
        </Container>
    );
}

export default UserFront;
