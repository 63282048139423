import React, { useState, useRef } from 'react';
import axios from 'axios';
import { Form, Button, ProgressBar, Alert } from 'react-bootstrap';
import Dropzone from 'react-dropzone';

const API_URL = process.env.REACT_APP_API_URL;

function FileForm({ authToken, onUploadSuccess }) {
    const [files, setFiles] = useState([]);
    const [progress, setProgress] = useState({});
    const [uploading, setUploading] = useState(false);
    const [error, setError] = useState(null);
    const fileInputRef = useRef();

    const handleDrop = (acceptedFiles) => {
        setFiles(acceptedFiles);
    };

    const handleUpload = () => {
        setUploading(true);
        setError(null);

        const promises = files.map((file) => {
            const formData = new FormData();
            formData.append('title', file.name); // Автоматически присваиваем название по имени файла
            formData.append('file', file);

            return axios.post(`${API_URL}/admin/files`, formData, {
                headers: {
                    'Authorization': `Bearer ${authToken}`,
                    'Content-Type': 'multipart/form-data'
                },
                onUploadProgress: (event) => {
                    const percentage = Math.round((event.loaded * 100) / event.total);
                    setProgress(prevProgress => ({
                        ...prevProgress,
                        [file.name]: percentage,
                    }));
                }
            });
        });

        Promise.all(promises)
            .then(() => {
                setFiles([]);
                setProgress({});
                setUploading(false);
                onUploadSuccess(); // Обновляем список после загрузки
            })
            .catch((error) => {
                setError("Ошибка загрузки файлов.");
                setUploading(false);
            });
    };

    return (
        <div>
            <Dropzone onDrop={handleDrop} multiple>
                {({ getRootProps, getInputProps }) => (
                    <div {...getRootProps()} className="dropzone">
                        <input {...getInputProps()} />
                        <p>Перетащите сюда файлы или нажмите, чтобы выбрать</p>
                    </div>
                )}
            </Dropzone>

            {files.length > 0 && (
                <div className="file-list mt-3">
                    {files.map((file) => (
                        <div key={file.name} className="file-item">
                            <p>{file.name}</p>
                            {uploading && (
                                <ProgressBar now={progress[file.name] || 0} label={`${progress[file.name] || 0}%`} />
                            )}
                        </div>
                    ))}
                </div>
            )}

            {error && <Alert variant="danger">{error}</Alert>}

            <Button variant="primary" onClick={handleUpload} disabled={uploading || files.length === 0}>
                {uploading ? 'Загружаем...' : 'Загрузить'}
            </Button>
        </div>
    );
}

export default FileForm;
