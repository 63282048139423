import React, { useState, useEffect, useRef } from 'react';
import axios from 'axios';
import { Button, Badge, Tab, Tabs, Container, Row, Col, Card, Spinner, Collapse, Form } from 'react-bootstrap';
import WaveSurfer from 'wavesurfer.js';
import { FaPlay, FaPause, FaRedo, FaVolumeUp, FaSlidersH } from 'react-icons/fa';
import Fuse from 'fuse.js';

const API_URL = process.env.REACT_APP_API_URL;

function GroupedFiles({ authToken }) {
    const [files, setFiles] = useState([]);
    const [categories, setCategories] = useState([]);
    const [isPlaying, setIsPlaying] = useState({});
    const [playingFileId, setPlayingFileId] = useState(null);
    const [currentTime, setCurrentTime] = useState({});
    const [fileDurations, setFileDurations] = useState({});
    const [isLoading, setIsLoading] = useState(true);
    const [showMarkers, setShowMarkers] = useState({});
    const [searchQueries, setSearchQueries] = useState({});
    const [globalVolume, setGlobalVolume] = useState(1); 
    const [cardsPerRow, setCardsPerRow] = useState(7); 
    const [showSettings, setShowSettings] = useState(false); 
    const [inputCardsPerRow, setInputCardsPerRow] = useState(cardsPerRow); 
    const [showTags, setShowTags] = useState(true); // Состояние для отображения тегов
    const [showTime, setShowTime] = useState(true); // Состояние для отображения времени файла

    const waveSurferRefs = useRef({});
    const waveSurferContainers = useRef({});
    
    const formatTime = (time) => {
        const minutes = Math.floor(time / 60);
        const seconds = Math.floor(time % 60);
        return `${minutes}:${seconds < 10 ? '0' : ''}${seconds}`;
    };
    
    const parseTime = (timeString) => {
        const [minutes, secondsFraction] = timeString.split(':');
        const [seconds, milliseconds] = secondsFraction.split('.');
        const totalSeconds = (parseInt(minutes, 10) * 60) + parseFloat(seconds + '.' + (milliseconds || 0));
        return totalSeconds;
    };



    useEffect(() => {
        fetchCategoriesAndFiles();

        const savedCardsPerRow = localStorage.getItem('cardsPerRow');
        if (savedCardsPerRow) {
            const parsedCardsPerRow = parseInt(savedCardsPerRow, 10);
            if (!isNaN(parsedCardsPerRow)) {
                setCardsPerRow(parsedCardsPerRow);
                setInputCardsPerRow(parsedCardsPerRow);
            }
        }

        const savedVolume = localStorage.getItem('globalVolume');
        const parsedVolume = parseFloat(savedVolume);
        if (!isNaN(parsedVolume) && parsedVolume >= 0 && parsedVolume <= 1) {
            setGlobalVolume(parsedVolume);
        }

        const savedShowTags = localStorage.getItem('showTags');
        if (savedShowTags !== null) {
            setShowTags(savedShowTags === 'true');
        }

        const savedShowTime = localStorage.getItem('showTime');
        if (savedShowTime !== null) {
            setShowTime(savedShowTime === 'true');
        }
    }, []);

    const fetchCategoriesAndFiles = () => {
        setIsLoading(true);
        const categoriesRequest = axios.get(`${API_URL}/admin/categories`, {
            headers: { Authorization: `Bearer ${authToken}` }
        });
        const filesRequest = axios.get(`${API_URL}/files`, {
            headers: { Authorization: `Bearer ${authToken}` }
        });

        Promise.all([categoriesRequest, filesRequest])
            .then(([categoriesResponse, filesResponse]) => {
                setCategories(categoriesResponse.data);
                setFiles(filesResponse.data);
                setIsLoading(false);
            })
            .catch((error) => {
                console.error("Error fetching categories and files:", error);
                setIsLoading(false);
            });
    };

    const initializeWaveSurfer = (file) => {
        const container = waveSurferContainers.current[file.id];
        if (!container) return;

        if (waveSurferRefs.current[file.id]) {
            waveSurferRefs.current[file.id].destroy();
        }

        const waveSurfer = WaveSurfer.create({
            container,
            waveColor: '#e9ecef',
            progressColor: '#007bff',
            cursorColor: '#007bff',
            height: 40,
            barWidth: 5,
            normalize: true,
            backend: 'MediaElement',
            interact: true,
        });

        waveSurfer.load(file.path);

        waveSurfer.on('ready', () => {
            waveSurfer.setVolume(globalVolume); 
            setFileDurations((prev) => ({ ...prev, [file.id]: waveSurfer.getDuration() }));
        });

        waveSurfer.on('audioprocess', () => {
            setCurrentTime((prev) => ({ ...prev, [file.id]: waveSurfer.getCurrentTime() }));
        });

        waveSurfer.on('finish', () => {
            setIsPlaying((prev) => ({ ...prev, [file.id]: false }));
            setPlayingFileId(null);
            waveSurfer.seekTo(0);
        });

        waveSurferRefs.current[file.id] = waveSurfer;
    };

    useEffect(() => {
        files.forEach((file) => {
            initializeWaveSurfer(file);
        });
    }, [files, cardsPerRow]);

    useEffect(() => {
        categories.forEach((category) => {
            getFilteredFiles(category.id).forEach((file) => initializeWaveSurfer(file));
        });
    }, [searchQueries]);

    const handlePlayPause = (file) => {
        const waveSurfer = waveSurferRefs.current[file.id];
        if (!waveSurfer) return;

        if (playingFileId && playingFileId !== file.id) {
            const playingWaveSurfer = waveSurferRefs.current[playingFileId];
            if (playingWaveSurfer) {
                playingWaveSurfer.pause();
                setIsPlaying((prev) => ({ ...prev, [playingFileId]: false }));
            }
        }

        if (isPlaying[file.id]) {
            waveSurfer.pause();
            setIsPlaying((prev) => ({ ...prev, [file.id]: false }));
        } else {
            waveSurfer.play();
            setIsPlaying((prev) => ({ ...prev, [file.id]: true }));
            setPlayingFileId(file.id);
        }
    };

    const handleReset = (file) => {
        const waveSurfer = waveSurferRefs.current[file.id];
        if (waveSurfer) {
            waveSurfer.seekTo(0);
            setCurrentTime((prev) => ({ ...prev, [file.id]: 0 }));
        }
    };

    const handleMarkerClick = (fileId, timeString) => {
        const waveSurfer = waveSurferRefs.current[fileId];
        const timeInSeconds = parseTime(timeString);
        if (waveSurfer && !isNaN(timeInSeconds)) {
            waveSurfer.seekTo(timeInSeconds / waveSurfer.getDuration());
        }
    };

    const toggleMarkers = (fileId) => {
        setShowMarkers((prev) => ({ ...prev, [fileId]: !prev[fileId] }));
    };

    const handleSearchChange = (categoryId, searchValue) => {
        setSearchQueries((prev) => ({ ...prev, [categoryId]: searchValue }));
    };

    const handleClearSearch = (categoryId) => {
        setSearchQueries((prev) => ({ ...prev, [categoryId]: '' }));
    };

    const getFilteredFiles = (categoryId) => {
        const categoryFiles = files.filter((file) => file.category === categoryId);
        if (!searchQueries[categoryId]) return categoryFiles;

        const fuse = new Fuse(categoryFiles, {
            keys: ['title'],
            threshold: 0.5,
        });

        return fuse.search(searchQueries[categoryId]).map((result) => result.item);
    };

    const handleGlobalVolumeChange = (event) => {
        const volume = parseFloat(event.target.value);
        const normalizedVolume = Math.min(Math.max(volume, 0), 1);

        setGlobalVolume(normalizedVolume);
        localStorage.setItem('globalVolume', normalizedVolume);

        Object.keys(waveSurferRefs.current).forEach((fileId) => {
            const waveSurfer = waveSurferRefs.current[fileId];
            if (waveSurfer) {
                waveSurfer.setVolume(normalizedVolume);
            }
        });
    };

    const handleCardsPerRowChange = (event) => {
        event.preventDefault();
        let value = parseInt(inputCardsPerRow, 10);

        // Ограничиваем значение от 1 до 12
        if (isNaN(value) || value < 1) {
            value = 1;
        } else if (value > 12) {
            value = 12;
        }

        setCardsPerRow(value);
        setInputCardsPerRow(value); // Обновляем поле ввода, если значение скорректировано
        localStorage.setItem('cardsPerRow', value);
    };

    const handleKeyPress = (event) => {
        if (event.key === 'Enter') {
            handleCardsPerRowChange(event);
        }
    };

    const handleToggleTags = () => {
        const newShowTags = !showTags;
        setShowTags(newShowTags);
        localStorage.setItem('showTags', newShowTags);
    };

    const handleToggleTime = () => {
        const newShowTime = !showTime;
        setShowTime(newShowTime);
        localStorage.setItem('showTime', newShowTime);
    };

    const renderRowsWithCards = (files, cardsPerRow) => {
        const rows = [];
        for (let i = 0; i < files.length; i += cardsPerRow) {
            const rowFiles = files.slice(i, i + cardsPerRow);
            rows.push(
                <Row key={i} className="mb-4">
                    {rowFiles.map((file) => (
                        <Col key={file.id} style={{ width: `${100 / cardsPerRow}%` }}>
                            <Card className="p-3" style={{ minHeight: '200px' }}>
                                <Row className="align-items-center">
                                    <Col md={12}>
                                        <h5 style={{ fontSize: file.title.length > 20 ? '16px' : '20px' }}>
                                            {file.title}
                                        </h5>
                                        {showTags && (
                                            <p>
                                                {file.tags
                                                    ? file.tags.split(',').map((tag) => (
                                                        <Badge key={tag} pill bg="secondary" className="me-1">
                                                            {tag}
                                                        </Badge>
                                                    ))
                                                    : 'No tags'}
                                            </p>
                                        )}
                                        <div
                                            ref={(el) => (waveSurferContainers.current[file.id] = el)}
                                            style={{
                                                cursor: 'pointer',
                                                height: '60px',
                                                marginBottom: '10px',
                                            }}
                                        ></div>
                                    </Col>
                                </Row>
                                <Row className="align-items-center">
                                    <Col md={12} className="d-flex justify-content-start align-items-center">
                                        <Button variant="primary" onClick={() => handlePlayPause(file)} className="me-2">
                                            {isPlaying[file.id] ? <FaPause /> : <FaPlay />}
                                        </Button>
                                        <Button variant="secondary" onClick={() => handleReset(file)} className="me-2">
                                            <FaRedo />
                                        </Button>
                                        {showTime && (
                                            <span style={{ marginLeft: '10px' }}>
                                                {formatTime(currentTime[file.id] || 0)} / {formatTime(fileDurations[file.id] || 0)}
                                            </span>
                                        )}
                                    </Col>
                                </Row>
                                {file.markers && file.markers.length > 0 && (
                                    <>
                                        <Button
                                            onClick={() => toggleMarkers(file.id)}
                                            aria-controls={`markers-${file.id}`}
                                            aria-expanded={showMarkers[file.id]}
                                            variant="link"
                                        >
                                            Показать метки
                                        </Button>
                                        <Collapse in={showMarkers[file.id]}>
                                            <div id={`markers-${file.id}`}>
                                                {file.markers.map((marker, index) => (
                                                    <Button
                                                        key={index}
                                                        onClick={() => handleMarkerClick(file.id, marker.time)}
                                                        variant="outline-primary"
                                                        size="sm"
                                                    >
                                                        {marker.comment} ({marker.time})
                                                    </Button>
                                                ))}
                                            </div>
                                        </Collapse>
                                    </>
                                )}
                            </Card>
                        </Col>
                    ))}
                </Row>
            );
        }
        return rows;
    };

    return (
        <Container fluid className="mt-5">
            <h1 className="text-center mb-4">Контент</h1>
            
            <div className="text-center mb-4">
                <Button onClick={() => setShowSettings(!showSettings)}>
                    <FaSlidersH /> Настройки отображения
                </Button>
                <Collapse in={showSettings}>
                    <div className="settings-container mt-4">
                        <Col md={4} className="mx-auto border p-3">
                            <Form onSubmit={handleCardsPerRowChange}>
                                <Form.Group>
                                    <Form.Label>Количество карточек в строке:</Form.Label>
                                    <Form.Control
                                        type="number"
                                        value={inputCardsPerRow}
                                        onChange={(e) => setInputCardsPerRow(e.target.value)}
                                        onKeyPress={handleKeyPress}
                                    />
                                    <Button onClick={handleCardsPerRowChange} className="mt-2">
                                        Применить
                                    </Button>
                                </Form.Group>
                                <Form.Group controlId="showTagsCheckbox" className="mt-3">
                                    <Form.Check 
                                        type="checkbox" 
                                        label="Отображать теги" 
                                        checked={showTags} 
                                        onChange={(e) => {
                                            setShowTags(e.target.checked);
                                            localStorage.setItem('showTags', e.target.checked);
                                        }} 
                                    />
                                </Form.Group>
                                <Form.Group controlId="showTimeCheckbox" className="mt-3">
                                    <Form.Check 
                                        type="checkbox" 
                                        label="Отображать время файла" 
                                        checked={showTime} 
                                        onChange={(e) => {
                                            setShowTime(e.target.checked);
                                            localStorage.setItem('showTime', e.target.checked);
                                        }} 
                                    />
                                </Form.Group>
                            </Form>
                        </Col>
                    </div>
                </Collapse>
            </div>


            {isLoading ? (
                <div className="text-center my-4">
                    <Spinner animation="border" />
                </div>
            ) : (
                <Tabs defaultActiveKey={categories.length > 0 ? categories[0].id : ''} id="file-categories" className="mb-3">
                    {categories.map((category) => (
                        <Tab eventKey={category.id} title={category.name} key={category.id}>
                            <div className="mb-3">
                                <Form.Group controlId={`search-${category.id}`}>
                                    <Form.Control
                                        type="text"
                                        placeholder="Поиск по названию"
                                        value={searchQueries[category.id] || ''}
                                        onChange={(e) => handleSearchChange(category.id, e.target.value)}
                                    />
                                    {searchQueries[category.id] && (
                                        <Button variant="outline-secondary" onClick={() => handleClearSearch(category.id)} className="mt-2">
                                            Очистить поиск
                                        </Button>
                                    )}
                                </Form.Group>
                            </div>
                            
                            <div className="text-center mb-4">
                                <label htmlFor="global-volume" className="me-2">
                                    <FaVolumeUp /> Общая громкость:
                                </label>
                                <input
                                    type="range"
                                    id="global-volume"
                                    min="0"
                                    max="1"
                                    step="0.01"
                                    value={globalVolume}
                                    onChange={handleGlobalVolumeChange}
                                    style={{ width: '300px' }}
                                />
                            </div>
                            
                            {renderRowsWithCards(getFilteredFiles(category.id), cardsPerRow)}
                        </Tab>
                    ))}
                </Tabs>
            )}
        </Container>
    );
}

export default GroupedFiles;
