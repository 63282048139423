// src/components/Login.js
import React, { useState } from 'react';
import axios from 'axios';
import { Container, Form, Button, Row, Col, Alert } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';

const API_URL = process.env.REACT_APP_API_URL;

function Login({ onLogin }) {
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const [error, setError] = useState('');
    const navigate = useNavigate();

    const handleSubmit = (e) => {
        e.preventDefault();
        setError('');

        console.log('Username:', username);  // Проверяем, что username не пустой
        console.log('Password:', password);  // Проверяем, что password не пустой

        axios.post(`${API_URL}/login`, {
            username: username,
            password: password
        })
        .then(response => {
            console.log('Response data:', response.data); // Проверяем ответ от сервера
            if (response.data.token) {
                console.log("Token received from backend:", response.data.token);
                onLogin(response.data.token);
                navigate('/grouped-files'); // Перенаправление после успешного логина
            } else {
                setError('Login failed: No token received.');
            }
        })
        .catch(error => {
            console.error("Login error:", error);
            setError('Invalid username or password');
        });
    };

    return (
        <Container className="mt-5">
            <Row className="justify-content-md-center">
                <Col md={5}>
                    <h2 className="text-center">Дооооообрый вечер, ребят</h2>
                    {error && <Alert variant="danger">{error}</Alert>}
                    <Form onSubmit={handleSubmit}>
                        <Form.Group controlId="formUsername" className="mb-3">
                            <Form.Label>Логин, ребят</Form.Label>
                            <Form.Control
                                type="text"
                                placeholder="Как зовут, ребят?"
                                value={username}
                                onChange={(e) => setUsername(e.target.value)}
                            />
                        </Form.Group>

                        <Form.Group controlId="formPassword" className="mb-3">
                            <Form.Label>Пароль, ребят</Form.Label>
                            <Form.Control
                                type="password"
                                placeholder="Внимааательней, ребят, внимательней"
                                value={password}
                                onChange={(e) => setPassword(e.target.value)}
                            />
                        </Form.Group>

                        <Button variant="primary" type="submit" className="w-100">
                            Полетели!
                        </Button>
                    </Form>
                </Col>
            </Row>
        </Container>
    );
}

export default Login;
