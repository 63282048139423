import React, { useState, useEffect, useRef } from 'react';
import { Form, Row, Col, Button } from 'react-bootstrap';

function SearchBar({ onSearch }) {
    const [keyword, setKeyword] = useState('');
    const prevKeywordRef = useRef('');

    useEffect(() => {
        const handler = setTimeout(() => {
            if (keyword.trim() !== prevKeywordRef.current) {
                onSearch(keyword.trim());
                prevKeywordRef.current = keyword.trim();
            }
        }, 500);

        return () => {
            clearTimeout(handler);
        };
    }, [keyword, onSearch]);

    const handleClear = () => {
        setKeyword('');
        onSearch('');
    };

    const handleSubmit = (event) => {
        event.preventDefault(); // Предотвращаем стандартное поведение формы
        onSearch(keyword.trim()); // Триггерим поиск сразу
    };

    return (
        <Form className="mb-4" onSubmit={handleSubmit}>
            <Row>
                <Col md={10}>
                    <Form.Control
                        type="text"
                        placeholder="То ли Дзюба, то ли Коцюба..."
                        value={keyword}
                        onChange={(e) => setKeyword(e.target.value)}
                    />
                </Col>
                <Col md={2}>
                    <Button variant="secondary" onClick={handleClear} className="w-100">
                        Очистить
                    </Button>
                </Col>
            </Row>
        </Form>
    );
}

export default SearchBar;
