// src/index.js
import React from 'react';
import { createRoot } from 'react-dom/client';
import App from './App';
import 'bootstrap/dist/css/bootstrap.min.css';
import './index.css'; // Ваши стили, если они есть
import './App.css';

// Найдите корневой элемент в HTML-документе
const container = document.getElementById('root');

// Создайте корневой React-компонент с помощью createRoot
const root = createRoot(container);

// Отрендерите ваше приложение
root.render(
    <React.StrictMode>
        <App />
    </React.StrictMode>
);
