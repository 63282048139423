import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';
import AdminPanel from './components/AdminPanel';
import Login from './components/Login';
import UserFront from './components/UserFront';
import FileManager from './components/FileManager';
import GroupedFiles from './components/GroupedFiles'; // Импортируем новый компонент
import NavigationBar from './components/NavigationBar';
import axios from 'axios';
import Cookies from 'js-cookie'; // Подключаем библиотеку js-cookie

function App() {
    const [authToken, setAuthToken] = useState(null); // Изначально токен не установлен
    const [isLoading, setIsLoading] = useState(true); // Состояние загрузки

    // useEffect для проверки токена при загрузке приложения
    useEffect(() => {
        const token = Cookies.get('authToken');
        if (token) {
            setAuthToken(token);
            axios.defaults.headers.common['Authorization'] = `Bearer ${token}`; // Установка заголовка с токеном
        }
        setIsLoading(false); // Загрузка завершена
    }, []);

    const handleLogin = (token) => {
        console.log("Login successful, token received:", token);
        setAuthToken(token);

        // Определяем, используется ли HTTPS
        const isSecure = window.location.protocol === 'https:';

        // Устанавливаем cookies с учетом протокола
        Cookies.set('authToken', token, {
            expires: 1, // Время жизни cookies
            secure: isSecure, // Используем secure только если HTTPS
            sameSite: isSecure ? 'None' : 'Lax', // Используем 'None' для кросс-доменных запросов на HTTPS
            path: '/', // Устанавливаем путь для cookies
            domain: window.location.hostname // Домен, на котором будут использоваться cookies
        });

        axios.defaults.headers.common['Authorization'] = `Bearer ${token}`; // Установка заголовка с токеном
    };

    const handleLogout = () => {
        setAuthToken(null);
        Cookies.remove('authToken', { path: '/', domain: window.location.hostname }); // Удаляем токен из cookies с указанием пути и домена
        delete axios.defaults.headers.common['Authorization']; // Удаление заголовка
        console.log("Logged out, token removed");
    };

    // Если идет загрузка, можно показывать индикатор загрузки или пустой экран
    if (isLoading) {
        return <div>Loading...</div>;
    }

    return (
        <Router>
            <div className="App">
                {authToken && <NavigationBar onLogout={handleLogout} />}
                <Routes>
                    <Route path="/login" element={<Login onLogin={handleLogin} />} />
                    <Route 
                        path="/admin" 
                        element={authToken ? <AdminPanel authToken={authToken} onLogout={handleLogout} /> : <Navigate to="/login" />} 
                    />
                    <Route 
                        path="/user" 
                        element={authToken ? <UserFront /> : <Navigate to="/login" />} 
                    />
                    <Route path="/" element={<Navigate to={authToken ? "/grouped-files" : "/login"} />} />
                    <Route path="*" element={<Navigate to={authToken ? "/grouped-files" : "/login"} />} />
                    <Route path="/file-manager" element={authToken ? <FileManager authToken={authToken} /> : <Navigate to="/login" />} />
                    <Route path="/grouped-files" element={authToken ? <GroupedFiles authToken={authToken} /> : <Navigate to="/login" />} />
                </Routes>
            </div>
        </Router>
    );
}

export default App;
