import React, { useState, useEffect, useRef } from 'react';
import { ListGroup, Dropdown, Row, Col, Card, Spinner, Modal, Form, Button, ToastContainer, Toast, Badge } from 'react-bootstrap';
import { FaPlay, FaPause, FaStar } from 'react-icons/fa';
import FileForm from './FileForm';
import SearchBar from './SearchBar';
import WaveSurfer from 'wavesurfer.js';
import TagsInput from 'react-tagsinput';
import 'react-tagsinput/react-tagsinput.css';
import axios from 'axios';

const API_URL = process.env.REACT_APP_API_URL;

function FileList({ authToken, onEdit, onDelete }) {
    const [files, setFiles] = useState([]);
    const [isPlaying, setIsPlaying] = useState({});
    const [playingFileId, setPlayingFileId] = useState(null);
    const [currentTime, setCurrentTime] = useState({});
    const [fileDurations, setFileDurations] = useState({});
    const [isLoading, setIsLoading] = useState(true);
    const [showEditModal, setShowEditModal] = useState(false);
    const [editFileId, setEditFileId] = useState(null);
    const [editTitle, setEditTitle] = useState('');
    const [editTags, setEditTags] = useState([]);
    const [editImportant, setEditImportant] = useState(false);
    const [editMarkers, setEditMarkers] = useState(''); // Добавляем состояние для меток времени
    const [loadingEdit, setLoadingEdit] = useState(false);
    const [toastMessage, setToastMessage] = useState(null);
    const [toastType, setToastType] = useState('');
    const [showAddModal, setShowAddModal] = useState(false);

    const waveSurferRefs = useRef({});
    const waveSurferContainers = useRef({});

    useEffect(() => {
        fetchFiles(); // Загружаем все файлы при первом рендере
    }, []);

    const fetchFiles = (keyword = '') => {
        setIsLoading(true);
        const params = keyword ? { keyword } : {};
        axios.get(`${API_URL}/admin/files`, {
            headers: {
                'Authorization': `Bearer ${authToken}`,
            },
            params
        })
            .then(response => {
                setFiles(response.data);
                setIsLoading(false);
            })
            .catch(error => {
                console.error("Error fetching files:", error);
                setIsLoading(false);
            });
    };

    const createWaveSurfer = (fileId) => {
        const container = waveSurferContainers.current[fileId];
        if (!container) return;

        if (waveSurferRefs.current[fileId]) {
            waveSurferRefs.current[fileId].destroy();
        }

        const waveSurfer = WaveSurfer.create({
            container,
            waveColor: '#e9ecef',
            progressColor: '#007bff',
            cursorColor: '#007bff',
            height: 40,
            barWidth: 4,
            normalize: true,
            backend: 'MediaElement',
        });

        waveSurfer.load(files.find(file => file.id === fileId).path);

        waveSurfer.on('ready', () => {
            setFileDurations(prev => ({ ...prev, [fileId]: waveSurfer.getDuration() }));
        });

        waveSurfer.on('audioprocess', () => {
            setCurrentTime(prev => ({ ...prev, [fileId]: waveSurfer.getCurrentTime() }));
        });

        waveSurfer.on('seek', (progress) => {
            const duration = waveSurfer.getDuration();
            const newTime = progress * duration;
            setCurrentTime(prev => ({ ...prev, [fileId]: newTime }));
        });

        waveSurfer.on('interaction', () => {
            const currentProgress = waveSurfer.getCurrentTime() / waveSurfer.getDuration();
            setCurrentTime(prev => ({ ...prev, [fileId]: currentProgress * waveSurfer.getDuration() }));
        });

        waveSurfer.on('finish', () => {
            setIsPlaying(prev => ({ ...prev, [fileId]: false }));
            setPlayingFileId(null);
            waveSurfer.seekTo(0);
        });

        waveSurferRefs.current[fileId] = waveSurfer;
    };

    useEffect(() => {
        files.forEach(file => {
            createWaveSurfer(file.id);
        });
    }, [files]);

    const handlePlayPause = (file) => {
        const waveSurfer = waveSurferRefs.current[file.id];
        if (!waveSurfer) return;

        if (playingFileId && playingFileId !== file.id) {
            const playingWaveSurfer = waveSurferRefs.current[playingFileId];
            if (playingWaveSurfer) {
                playingWaveSurfer.pause();
                setIsPlaying(prev => ({ ...prev, [playingFileId]: false }));
            }
        }

        if (isPlaying[file.id]) {
            waveSurfer.pause();
            setIsPlaying(prev => ({ ...prev, [file.id]: false }));
        } else {
            waveSurfer.play();
            setIsPlaying(prev => ({ ...prev, [file.id]: true }));
            setPlayingFileId(file.id);
        }
    };

    const handleEditClick = (file) => {
        setEditFileId(file.id);
        setEditTitle(file.title);
        setEditTags(file.tags ? file.tags.split(',') : []);
        setEditImportant(file.isImportant);
        setEditMarkers(file.markers ? file.markers.map(marker => `${marker.time} - ${marker.comment}`).join(', ') : ''); // Инициализируем метки времени
        setShowEditModal(true);
    };

    const handleEditSave = () => {
        const updatedFile = {
            title: editTitle,
            tags: editTags.length > 0 ? editTags.join(',') : '',
            isImportant: editImportant,
            markers: editMarkers // Отправляем метки времени на сервер
        };

        setLoadingEdit(true);
        axios.put(`${API_URL}/admin/files/${editFileId}`, updatedFile, {
            headers: {
                'Authorization': `Bearer ${authToken}`,
                'Content-Type': 'application/json'
            }
        })
            .then(response => {
                setToastMessage("Успех, ребят!");
                setToastType('edit');
                setShowEditModal(false);
                setTimeout(() => setToastMessage(null), 3000);
                fetchFiles(); // Перезагружаем файлы после успешного редактирования
            })
            .catch(error => {
                setToastMessage("Хуйня вышла...");
                setToastType('error');
                setTimeout(() => setToastMessage(null), 3000);
            })
            .finally(() => {
                setLoadingEdit(false);
            });
    };

    const handleDelete = (fileId) => {
        axios.delete(`${API_URL}/admin/files/${fileId}`, {
            headers: {
                'Authorization': `Bearer ${authToken}`,
            }
        })
            .then(response => {
                setToastMessage("Удалено, ребят!");
                setToastType('delete');
                setTimeout(() => setToastMessage(null), 3000);
                fetchFiles(); // Перезагружаем файлы после успешного удаления
            })
            .catch(error => {
                setToastMessage("Хуйня вышла...");
                setToastType('error');
                setTimeout(() => setToastMessage(null), 3000);
            });
    };

    const formatTime = (time) => {
        const minutes = Math.floor(time / 60);
        const seconds = Math.floor(time % 60);
        return `${minutes}:${seconds < 10 ? '0' : ''}${seconds}`;
    };

    const handleFileUploadSuccess = () => {
        setShowAddModal(false);
        setToastMessage("Успех, ребят!");
        setToastType('upload');
        setTimeout(() => setToastMessage(null), 3000);
        fetchFiles(); // Перезагружаем файлы после успешной загрузки
    };

    return (
        <>
            <SearchBar onSearch={fetchFiles} />

            <Button variant="primary" onClick={() => setShowAddModal(true)} className="mb-3">Добавить файлик</Button>

            <Row>
                {isLoading ? (
                    <div className="text-center my-4">
                        <Spinner animation="border" />
                    </div>
                ) : (
                    files.length > 0 ? (
                        files.map((file, index) => (
                            <Col md={4} key={file.id} className="mb-4">
                                <Card className="p-3">
                                    <Row className="align-items-center">
                                        <Col md={12} className="text-center">
                                            <button
                                                onClick={() => handlePlayPause(file)}
                                                className="btn btn-link"
                                            >
                                                {isPlaying[file.id] ? <FaPause /> : <FaPlay />}
                                            </button>
                                        </Col>
                                        <Col md={12}>
                                            <h5>
                                                {file.title} {file.isImportant && <FaStar style={{ color: 'gold' }} />}
                                            </h5>
                                            <p>
                                                {file.tags ? file.tags.split(',').map(tag => (
                                                    <Badge key={tag} pill bg="secondary" className="me-1">{tag}</Badge>
                                                )) : 'No tags'}
                                            </p>
                                            <div
                                                ref={el => {
                                                    waveSurferContainers.current[file.id] = el;
                                                }}
                                                style={{ cursor: 'pointer' }}
                                            ></div>
                                            <span>
                                                {formatTime(currentTime[file.id] || 0)} / {formatTime(fileDurations[file.id] || 0)}
                                            </span>
                                        </Col>
                                        <Col md={12} className="text-right">
                                            <Dropdown>
                                                <Dropdown.Toggle variant="light" id="dropdown-basic">
                                                    &#x22EE;
                                                </Dropdown.Toggle>
                                                <Dropdown.Menu>
                                                    <Dropdown.Item onClick={() => handleEditClick(file)}>Изменить</Dropdown.Item>
                                                    <Dropdown.Item onClick={() => handleDelete(file.id)}>Удалить</Dropdown.Item>
                                                </Dropdown.Menu>
                                            </Dropdown>
                                        </Col>
                                    </Row>
                                </Card>
                            </Col>
                        ))
                    ) : (
                        <div className="text-center my-4">
                            <p>Ничего нема</p>
                        </div>
                    )
                )}
            </Row>

            <ToastContainer position="top-end" className="p-3">
                {toastMessage && (
                    <Toast>
                        <Toast.Header>
                            <strong className="me-auto">
                                {toastType === 'upload' && 'File Uploaded'}
                                {toastType === 'edit' && 'File Edited'}
                                {toastType === 'delete' && 'File Deleted'}
                                {toastType === 'error' && 'Error'}
                            </strong>
                        </Toast.Header>
                        <Toast.Body>{toastMessage}</Toast.Body>
                    </Toast>
                )}
            </ToastContainer>

            {/* Modal for Editing File Details */}
            <Modal show={showEditModal} onHide={() => setShowEditModal(false)}>
                <Modal.Header closeButton>
                    <Modal.Title>Изменить</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form>
                        <Form.Group controlId="editTitle" className="mb-3">
                            <Form.Control
                                type="text"
                                placeholder="Название"
                                value={editTitle}
                                onChange={(e) => setEditTitle(e.target.value)}
                            />
                        </Form.Group>
                        <Form.Group controlId="editTags" className="mb-3">
                            <Form.Label>Теги</Form.Label>
                            <TagsInput value={editTags} onChange={setEditTags} />
                        </Form.Group>
                        <Form.Group controlId="editMarkers" className="mb-3">
                            <Form.Label>Метки времени</Form.Label>
                            <Form.Control
                                as="textarea"
                                placeholder="00:03 - Чеченец бля, 00:07 - Я мусульманин"
                                value={editMarkers}
                                onChange={(e) => setEditMarkers(e.target.value)}
                            />
                        </Form.Group>
                        <Form.Group controlId="editImportant" className="mb-3">
                            <Form.Check
                                type="checkbox"
                                label="Избранное"
                                checked={editImportant}
                                onChange={(e) => setEditImportant(e.target.checked)}
                            />
                        </Form.Group>
                    </Form>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={() => setShowEditModal(false)}>
                        Отмена
                    </Button>
                    <Button variant="primary" onClick={handleEditSave} disabled={loadingEdit}>
                        {loadingEdit ? <Spinner as="span" animation="border" size="sm" /> : 'Полетели!'}
                    </Button>
                </Modal.Footer>
            </Modal>

            {/* Modal for Adding a New File */}
            <Modal show={showAddModal} onHide={() => setShowAddModal(false)}>
                <Modal.Header closeButton>
                    <Modal.Title>Добавить файлик</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <FileForm
                        authToken={authToken}
                        onUploadSuccess={handleFileUploadSuccess}
                    />
                </Modal.Body>
            </Modal>
        </>
    );
}

export default FileList;
